.store-badges {
	display: inline-flex;
	justify-content: center;

	&__item {
		text-align: center;

		a {
			display: block;
			font-size: 0;
			margin-bottom: 28px;
		}
	}
}
