$font-primary: 'Raleway', sans-serif;

$color-black: #000;
$color-white: #fff;
$color-purplish-blue: #3a36db;
$color-orange: #ffc555;
$color-orange-secondary: #fe7646;
$color-purple-primary: #4700df;
$color-purple-secondary: #8ba1ee;

$color-yellow-secondary: #ffb27a;
$color-text-gray1: #888;
$color-bg: #eff1fd;
$color-chat-bubble: #dee4f4;

$color-gray1: #f0f0f0;
