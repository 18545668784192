.sticky {
	@include sticky($top: 0);
	background-color: $color-white;
	border-bottom: 1px solid $color-chat-bubble;
	z-index: 10;
	margin-bottom: 100vh;

	&__content {
		flex-shrink: 0;
		display: flex;
		align-items: center;
		justify-content: space-between;
		max-width: 1440px;
		padding: 0 40px;
		margin: 0 auto;
		height: 60px;
	}

	&__apps {
		display: flex;

		a {
			font-size: 0;
			margin-left: 24px;
		}
	}
}

@include mobile-screen() {
	.sticky {
		&__content {
			padding: 0 16px;
		}

		&__logo {
			width: 104px;
			height: 36px;
		}
	}
}
