.main-content {
	flex-shrink: 0;
	position: relative;
	background-color: $color-bg;
	margin-top: -100vh;

	h2 {
		font-size: 48px;
		line-height: 1.14;

		strong {
			font-weight: 900;
		}
	}

	h3 {
		font-size: 28px;
	}

	h4 {
		font-size: 20px;
	}
}

.section-intro {
	padding-top: 160px;
	padding-bottom: 120px;

	&__image {
		grid-column: 2/span 3;
		line-height: 0;

		img {
			width: 100%;
		}
	}

	&__content {
		grid-column: 6/span 6;
		align-self: center;
		text-align: left;
	}

	h2 {
		margin-bottom: 40px;
	}

	p {
		font-size: 20px;
	}
}

.section-profile {
	position: relative;
	padding-top: 200px;
	margin-bottom: 180px;
	z-index: 1;

	&::before, &::after {
		content: '';
		width: 1400px;
		height: 1400px;
	}

	&::before {
		@include absolute($top: 0, $left: 0);
		background: url("../images/heart_2.svg") top left/contain no-repeat;
		z-index: -1;
	}

	&::after {
		@include absolute($top: 70%, $right: 0);
		background: url("../images/heart_3.svg") top right/contain no-repeat;
		z-index: -2;
	}

	h2 {
		color: $color-white;
		margin-bottom: 40px;

		strong {
			background: linear-gradient(102.65deg, #FE7845 12.67%, #FE4567 111.75%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			-webkit-box-decoration-break: clone;
		}
	}

	p {
		font-size: 24px;
		font-weight: 300;
		color: $color-white;
		margin-bottom: 52px;
	}

	&__image {
		grid-column: 2/span 10;
		line-height: 0;

		img {
			max-width: 1024px;
		}
	}
}

.section-wrapper {
	position: relative;
	z-index: 1;
}

.section-match {
	position: relative;
	z-index: 1;

	h2 {
		color: $color-white;
		margin-bottom: 48px;
		white-space: nowrap;

		mark {
			position: relative;
			display: inline-block;
			background: none;
			border-bottom: 0.125em solid currentColor;
			color: $color-yellow-secondary;
			text-transform: uppercase;
		}

		mark:last-child {
			color: $color-orange-secondary;
		}
	}

	p {
		font-size: 24px;
		color: $color-white;
	}

	p + p {
		margin-top: 1.6em;
	}

	&__image {
		grid-column: 1 / span 6;
		padding: 0 9%;
		line-height: 0;
	}

	&__content {
		grid-column: 7 / span 5;
		align-self: center;
		text-align: left;
	}
}

.section-features {
	position: relative;
	padding-top: 150px;
	padding-bottom: 164px;

	&::before {
		@include absolute($bottom: 0, $left: 0);
		content: '';
		background: url("../images/heart_4.svg") center/contain no-repeat;
		width: 1400px;
		height: 1400px;
		z-index: -2;
	}

	.container {
		row-gap: 18px;
	}
}

.feature {
	grid-column: 2 / span 5;
	text-align: left;
	padding: 0 48px;

	&__icon {
		width: 154px;
		height: 154px;
		margin: 0 0 0 -30px;
	}

	&__title {
		color: $color-white;
		margin-bottom: 20px;
	}

	&__desc {
		font-size: 20px;
		color: $color-chat-bubble;
	}

	&:nth-child(even) {
		grid-column-start: 7;
	}
}

.section-stories {
	padding: 170px 0 128px;

	h2 {
		margin-bottom: 48px;

		strong {
			text-transform: uppercase;
			background: linear-gradient(180deg, #A981FF 3.86%, #3014DB 134.06%);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			-webkit-box-decoration-break: clone;
		}
	}

	p {
		font-size: 20px;
		line-height: 1.6;
		margin-bottom: 60px;
	}

	a {
		color: $color-yellow-secondary;
		font-weight: bold;

		&::after {
			content: '';
			display: inline-block;
			border: 3px solid currentColor;
			border-top: none;
			border-left: none;
			width: 10px;
			height: 10px;
			transform: rotate(-45deg);
			margin-left: 8px;
		}
	}

	h4 {
		color: $color-purple-primary;
	}

	&__list {
		grid-column: 2 / span 10;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 20px;
		margin-top: 60px;
		z-index: 1;
	}
}

.review {
	display: flex;
	align-items: center;
	background-color: $color-white;
	border: 1px solid $color-gray1;
	border-radius: 40px;
	padding: 20px 20px 18px;

	&__photo {
		border-radius: 22%;
		width: 35%;
		height: auto;
		margin-right: 30px;
	}

	&__content {
		text-align: left;
	}

	&__text {
		font-size: 18px;
		font-weight: 500;
		line-height: 1.55;
		margin-bottom: 8px;
	}

	&__info {
		color: $color-text-gray1;
	}

	&:nth-child(odd) {
		border-bottom-left-radius: 0;
	}

	&:nth-child(even) {
		border-bottom-right-radius: 0;
	}
}

.section-promo {
	position: relative;
	background: linear-gradient(180deg, #3900B2 0%, #2C54E1 100%);
	padding: 182px 0;

	&::before {
		@include absolute($top: 0, $left: 50%);
		content: '';
		background-color: $color-bg;
		border-radius: 50%;
		width: 100px;
		height: 100px;
		transform: translate(-50%, -60%);
	}

	h2 {
		font-size: 60px;
		color: $color-white;
		margin-bottom: 40px;
	}

	p {
		font-size: 24px;
		color: $color-white;
		margin-bottom: 40px;
	}
}

@include table-screen() {
	.main-content {
		h2 {
			font-size: 36px;
		}

		h3 {
			font-size: 26px;
		}

		h4 {
			font-size: 18px;
		}
	}

	.section-intro {
		align-items: center;
		padding-top: 120px;
		padding-bottom: 140px;

		&__image {
			width: 128px;
			margin-bottom: 40px;
		}

		&__content {
			text-align: center;
		}

		h2 {
			margin-bottom: 32px;
		}

		p {
			font-size: 18px;
		}
	}

	.section-profile {
		background: linear-gradient(149.66deg, #4700DF 48.49%, #2C54E1 79.22%) no-repeat;
		background-position-y: 40px;
		padding-top: 40px;
		padding-bottom: 140px;
		margin-bottom: 0;

		&::before, &::after {
			width: 112%;
			height: 100%;
		}

		&::before {
			top: -60px
		}

		&::after {
			top: calc(100% - 100px);
		}

		h2 {
			margin-bottom: 48px;
		}

		p {
			font-size: 20px;
			margin-bottom: 16px;
		}
	}

	.section-wrapper {
		background: linear-gradient(146.06deg, #A981FF 3.86%, #3014DB 134.06%) no-repeat;
		background-position-y: 40px;
		margin-top: -60px;
		z-index: auto;
	}

	.section-match {
		h2 {
			margin-bottom: 28px;
		}

		p {
			font-size: 20px;
		}

		&__image {
			padding: 0;
		}

		&__content {
			order: -1;
			text-align: center;
			padding-top: 0;
			margin-bottom: 60px;
		}
	}

	.section-features {
		row-gap: 40px;
		padding-top: 104px;
		padding-bottom: 108px;

		&::before {
			content: none;
		}
	}

	.feature {
		text-align: center;
		padding: 0 16px;

		&__icon {
			width: 141px;
			height: 141px;
			margin: 0;
		}

		&__title {
			margin-bottom: 20px;
		}

		&__desc {
			font-size: 18px;
		}

		&:nth-child(1) {
			order: 1;
		}

		&:nth-child(2) {
			order: 3;
		}

		&:nth-child(3) {
			order: 2;
		}

		&:nth-child(4) {
			order: 4;
		}
	}

	.section-stories {
		padding: 80px 0 30px;

		h2 {
			margin-bottom: 24px;
		}

		p {
			font-size: 18px;
			margin-bottom: 24px;
		}

		a {
			display: inline-block;
			margin-top: 36px;

			&::after {
				border-width: 3px;
				width: 8px;
				height: 8px;
				margin-left: 4px;

			}
		}

		&__list {
			display: flex;
			flex-direction: column;
			row-gap: 60px;
			margin-top: 72px;
		}
	}

	.review {
		flex-direction: column;
		border-radius: 16px !important;
		padding: 0 24px 20px;

		&__photo {
			border-radius: 20px;
			width: 88px;
			height: 88px;
			margin: -32px 0 12px 0;
		}

		&__content {
			text-align: center;
		}

		&__text {
			font-size: 16px;
		}
	}

	.section-promo {
		padding: 174px 8px;

		&__ico {
			width: 274px;
			height: 104px;
		}

		h2 {
			margin-bottom: 24px;
		}

		p {
			font-size: 20px;
			margin-bottom: 24px;
		}
	}
}

@include mobile-screen() {
	.main-content {
		h2, h3 {
			font-size: 24px;
		}

		h4 {
			font-size: 16px;
		}
	}

	.section-intro {
		padding-top: 84px;

		p {
			font-size: 16px;
		}
	}

	.section-profile {
		p {
			font-size: 16px;
		}

		&__image {
			margin: 0 -20px;
		}
	}

	.section-match {
		p {
			font-size: 16px;
		}

		&__image {
			margin: 0 -20px;
		}
	}

	.feature {
		&__icon {
			width: 128px;
			height: 128px;
		}

		&__desc {
			font-size: 16px;
		}
	}

	.section-stories {
		p {
			font-size: 16px;
		}

		&__list {
			margin-right: -8px;
			margin-left: -8px;
		}
	}

	.review {
		&__content {
			text-align: center;
		}

		&__text, &__info {
			font-size: 14px;
		}
	}

	.section-promo {
		&::before {
			width: 60px;
			height: 60px;
		}

		p {
			font-size: 16px;
		}
	}
}


