//@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;900&display=swap');

@font-face {
	font-family: 'Raleway';
	src: url("../fonts/Raleway-Regular.ttf");
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url("../fonts/Raleway-Medium.ttf");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url("../fonts/Raleway-Bold.ttf");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url("../fonts/Raleway-Black.ttf");
	font-weight: 900;
	font-style: normal;
}
