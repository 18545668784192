.header {
	flex: 1 0 100%;
	position: relative;
	background: url("../images/bg.jpg") center/cover;

	&__content {
		@include absolute($top: 0, $left: 0);
		background: url("../images/heart_1.svg") top left / contain no-repeat;
		text-align: left;
		padding: 146px 160px 0 88px;
		width: 595px;
		height: 596px;
	}

	&__logo {
		display: block;
		max-width: 100%;
		margin-bottom: 40px;
	}

	&__slogan {
		font-size: 32px;
		color: $color-orange;
		letter-spacing: 0.04em;
		line-height: 1.18;
		margin-bottom: 40px;
	}

	&__heart {
		@include absolute($top: 40px, $left: 40px);
	}

	&__banner {
		@include absolute($top: 0, $left: 0);
		box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
		width: 100%;
	}
}

.mobile {
	.header {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		background: url("../images/bg_m.jpg") top/cover;
		padding: 0 16px 8vh;

		&__content {
			top: 80px;
			width: 300px;
			height: 300px;
			padding: 60px 68px 0 32px;
		}

		&__logo {
			margin-bottom: 24px;
		}

		&__slogan {
			font-size: 24px;
			margin-bottom: 0;
		}
	}
}

.store-banner {
	display: flex;
	align-items: center;
	background-color: $color-white;
	text-align: left;
	padding: 0 12px 0 8px;
	height: 80px;
	overflow: hidden;

	&__logo {
		flex-shrink: 0;
		font-size: 0;
		margin-right: 8px;
	}

	&__content {
		flex: 1 1 auto;
		line-height: 1.18;
		margin-right: 4px;
	}

	&__title {
		font-weight: bold;
		font-size: 12px;
		margin-bottom: 3px;
	}

	&__desc {
		font-size: 11px;
		color: $color-text-gray1;
		margin-bottom: 4px;
	}
}
